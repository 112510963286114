import { createThemes } from '@babylon/ui-kit-styles/common/themes';
import { ThemeId } from '@babylon/ui-kit-styles/types/theme';

import liverpoolCarouselCategoryStyles from './styles/liverpool';
import veciCarouselCategory from './styles/veci';

const CarouselCategoryThemes = createThemes({
	[ThemeId.veci]: veciCarouselCategory,
	[ThemeId.partnershipsLiverpool]: liverpoolCarouselCategoryStyles,
});

export default CarouselCategoryThemes;
