import type { FC } from 'react';

import { IMAGE_PROPS_MOBILE } from '../../constants/imageProps';
import type { CircuitsAdvantagesProps } from '../../types';
import * as S from './styled';

const CircuitsAdvantagesMobile: FC<CircuitsAdvantagesProps> = ({ props }) => (
	<S.ContainerMobile data-testid='CircuitsAdvantages'>
		{props?.imageSrc && <S.StyledImage src={props.imageSrc} resize={IMAGE_PROPS_MOBILE} />}
		<S.ContentWrapperMobile>
			{props?.title && <S.TitleMobile>{props.title}</S.TitleMobile>}
			{props?.titleList && <S.TitleListMobile>{props.titleList}</S.TitleListMobile>}
			{props?.list?.length && (
				<S.ListMobile>
					{props.list.map(item => (
						<S.ItemMobile key={JSON.stringify(item)}>
							<S.StyledIcon className='nico-check' />
							<S.StyledInnerMarkdownHTML tag='span' content={item} />
						</S.ItemMobile>
					))}
				</S.ListMobile>
			)}
		</S.ContentWrapperMobile>
	</S.ContainerMobile>
);

export default CircuitsAdvantagesMobile;
