import useIsLiverpool from '@babylon/ui-kit-styles/hooks/useIsLiverpool';
import type { FC } from 'react';
import { useTheme } from 'styled-components';

import type { CarouselCategoryProps } from '../../types';
import { getCardsCarousel } from '../../utils/getCardsCarousel';
import * as S from './styled';

const CarouselCategoryDesktop: FC<CarouselCategoryProps> = ({ props }) => {
	const { isLiverpool } = useIsLiverpool();
	const desktopCards = getCardsCarousel(props, false);
	const theme = useTheme();
	const carouselProps = {
		heading: {
			title: {
				content: props?.title?.title ? props.title.title : '',
			},
		},
		items: desktopCards,
		...(isLiverpool && {
			sliderConfig: {
				perPage: 5,
				gap: 20,
				breakpoints: {
					[theme.breakpoints.xxl]: { perPage: 4 },
					[theme.breakpoints.xl]: { perPage: 3 },
					[theme.breakpoints.lg]: { perPage: 2 },
				},
			},
		}),
	};

	return <div data-testid='CarouselCategory'>{carouselProps && <S.Carousel {...carouselProps} />}</div>;
};

export default CarouselCategoryDesktop;
