import Icon from '@babylon/ui-kit-base/components/icons/icon';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import Title from '@babylon/ui-kit-base/components/text/title';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { Margin, Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

import circuitsAdvantagesThemes from './themes';

export const Container = styled.div`
	${FlexMixin({})};
	background-color: #e9f7ef;
	position: relative;
	${circuitsAdvantagesThemes('Container')}
`;

export const ContentWrapper = styled.div`
	width: 100%;
	${Padding({ bottom: '44px', top: '38px' })};
`;

export const Content = styled.div`
	width: 63%;
	${FlexMixin({})};
`;

export const BlockTitle = styled(Title)`
	width: 63%;
	${Margin({ bottom: '16px' })};
	text-align: initial;
	h2 {
		padding: 0 10px 0 0;
		${Padding({ left: '0', right: '10px' })};
	}
`;

export const TitleList = styled.span`
	${FontMixin({ size: 'medium', weight: 'medium' })};
	line-height: 22px;
	color: ${({ theme }) => theme.colors.grays.darker};
	${Margin({ bottom: '12px' })};
`;

export const List = styled.ul`
	list-style: none;
`;

export const Item = styled.li`
	${FlexMixin({ align: 'start' })};
	${Margin({ bottom: '8px' })};
	column-gap: 8px;
`;

export const StyledIcon = styled(Icon)`
	${FontMixin({ size: 'base' })};
	color: ${({ theme }) => theme.colors.status.success};
	align-self: baseline;

	${circuitsAdvantagesThemes('StyledIcon')}
`;

export const StyledInnerMarkdownHTML = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'medium', height: 'base', weight: 'light' })};
	color: ${({ theme }) => theme.colors.grays.darker};
`;

export const ImageWrapper = styled.div`
	width: 37%;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	${({ theme }) => theme.media.down('lg')} {
		width: 0;
	}

	img {
		object-fit: cover;
		${({ theme }) => theme.media.down('lg')} {
			display: none;
		}
	}

	&::before {
		background-image: linear-gradient(to left, rgba(255, 0, 0, 0), rgba(233, 247, 239, 1));
		bottom: 0;
		content: '';
		position: absolute;
		top: 0;
		width: 120px;
		${circuitsAdvantagesThemes('ImageWrapperEffect')}
	}

	figure {
		height: 100%;

		img {
			height: 100%;
		}
	}
`;
