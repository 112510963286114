import CarouselGeneric from '@babylon/ui-kit-carousels/components/carousel-generic/index';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { Margin } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

import carouselCategoryThemes from '../themes';

export const OtherCategories = styled.h3`
	text-align: center;
	${FontMixin({ size: 'large', height: 'large', weight: 'medium' })};
	color: ${({ theme }) => theme.colors.grays.darker};
	${Margin({ top: '20px', bottom: '16px' })};
`;

export const Carousel = styled(CarouselGeneric)`
	${carouselCategoryThemes('Carousel')}
`;
