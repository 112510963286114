export const IMAGE_PROPS = {
	width: 730,
	height: 397,
	suboperation: null,
};

export const IMAGE_PROPS_MOBILE = {
	width: 393,
	height: 180,
};
