import { ErrorBoundary } from '@babylon/ui-kit-helpers/error-boundary';
import type { FC, ReactElement } from 'react';
import { Children } from 'react';

import type { ComponentMap, ComponentPropMap, MainHomeOrderComponents } from '../types';

export const combineProps = (props: string[], componentPropMapping: ComponentPropMap) => {
	const componentProps = props.map(propKey => componentPropMapping[propKey]);
	const combinedComponentProps = Object.assign({}, ...componentProps);

	return combinedComponentProps;
};

/*
	Render same as "Context.order" from Alexandria model, everything is based on the mappings,
	if a component/prop does not exist in the mapping, it will not be rendered.:

	1. filter out components that does not exist in componentMapping.
	2. map over components and render them.
		2.1. if component has props, combine them.
		2.2. if component has props but does not exist in componentPropMapping return null.
	3. if component has children, render them recursively and start from step 1.
*/
export const renderComponentsWithHierarchyAndOrder = (
	renderComponents: MainHomeOrderComponents[],
	componentMapping: ComponentMap,
	componentPropMapping: ComponentPropMap
): ReactElement => (
	<>
		{Children.toArray(
			renderComponents
				// filter out components that does not exist in componentMapping
				.filter(({ component }) => component in componentMapping)
				.map(({ component, props, children }) => {
					componentMapping[component].displayName = component;
					const Component = ErrorBoundary(componentMapping[component]);
					const componentProperties = combineProps(props ?? [], componentPropMapping);

					// component has props but does not exist in componentPropMapping (props with items and componentProps as {}).
					if (Object.keys(componentProperties).length === 0 && (props ?? []).length !== 0) return null;

					return children ? (
						<Component {...componentProperties}>
							{renderComponentsWithHierarchyAndOrder(children, componentMapping, componentPropMapping)}
						</Component>
					) : (
						<Component {...componentProperties} />
					);
				})
		)}
	</>
);

// TODO: change props: any type
export const renderComponentsWithOrder = (order: string[], props: any, componentMapping: { [x: string]: FC<any> }) => {
	// filter out components that does not exist in component mapping
	const componentsOrderOnlyInMapping = order.filter(component => component in componentMapping && props[component]);

	return componentsOrderOnlyInMapping.map((component, index) => {
		componentMapping[component].displayName = component;
		const Component = ErrorBoundary(componentMapping[component]);
		const componentProps = props[component];
		return <Component key={index} {...componentProps} />;
	});
};

export const excludeComponentsByList = (inputList: string[], excludeList: string[]) =>
	inputList.filter(item => !excludeList.includes(item));
