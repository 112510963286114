import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { css } from 'styled-components';

import type { CarouselCategoryStyles } from '../types';

const veciCarouselCategoryStyles: CarouselCategoryStyles = {
	StyledCardSimple: () => css`
		h3 {
			${FontMixin({ size: 'larger', height: 'larger' })};
			border: unset;
		}
		a {
			span {
				text-transform: lowercase;
			}
		}
	`,
};

export default veciCarouselCategoryStyles;
