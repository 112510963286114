import CardSimple from '@babylon/ui-kit-cards/components/cards/card-simple';
import CarouselGeneric from '@babylon/ui-kit-carousels/components/carousel-generic/index';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { Margin, Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

import carouselCategoryThemes from '../themes';

export const StyledCardSimple = styled(CardSimple)`
	a {
		span {
			border: unset;
			${Margin({ bottom: 'unset' })};
			${({ theme }) => theme.media.down('sm')} {
				${FontMixin({ size: 'large', height: 'large', weight: 'medium' })};
			}
		}
		span:nth-child(2) {
			${Padding({ top: 'unset' })};
			${FontMixin({ size: 'base', height: 'base' })};
			${({ theme }) => theme.media.down('sm')} {
				${FontMixin({})};
			}
		}
	}

	${carouselCategoryThemes('StyledCardSimple')}
`;

export const Carousel = styled(CarouselGeneric)`
	${carouselCategoryThemes('Carousel')}
`;
