import Icon from '@babylon/ui-kit-base/components/icons/icon';
import Image from '@babylon/ui-kit-base/components/multimedia/image';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { Margin, Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

export const ContainerMobile = styled.div`
	${FlexMixin({ direction: 'column' })};
`;

export const StyledImage = styled(Image)`
	min-height: 180px;
`;

export const ContentWrapperMobile = styled.div`
	${Padding({ top: '12px', x: '16px', bottom: '28px' })};
	background-color: #e9f7ef;
`;

export const TitleMobile = styled.h2`
	font-family: ${({ theme }) => theme.font.family.secondary};
	${FontMixin({ size: 'larger', height: 'larger', weight: 'medium' })};
	color: ${({ theme }) => theme.colors.grays.darker};
	${Margin({ bottom: '12px' })};
	text-align: center;
`;

export const TitleListMobile = styled.p`
	font-family: ${({ theme }) => theme.font.family.secondary};
	${FontMixin({ size: 'medium', height: 'base', weight: 'bold' })};
	color: ${({ theme }) => theme.colors.grays.darker};
	${Margin({ bottom: '8px' })};
`;

export const ListMobile = styled.ul`
	list-style: none;
`;

export const ItemMobile = styled.li`
	${FlexMixin({ align: 'start' })};
	${Margin({ bottom: '8px' })};
	column-gap: 8px;
`;

export const StyledIcon = styled(Icon)`
	${FontMixin({ size: 'base' })};
	color: ${({ theme }) => theme.colors.status.success};
	align-self: baseline;
`;

export const StyledInnerMarkdownHTML = styled(InnerMarkdownHTML)`
	font-family: ${({ theme }) => theme.font.family.primary};
	${FontMixin({ size: 'medium', height: 'base', weight: 'light' })};
	color: ${({ theme }) => theme.colors.grays.darker};
`;
