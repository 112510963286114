export const CARD_SIMPLE_DESKTOP_PROPS = {
	width: '279',
	height: '360',
};

export const CARD_SIMPLE_MOBILE_PROPS = {
	width: '194px',
	height: '250px',
};

export const CAROUSEL_MOBILE_PROPS = {
	viewHorizontal: true,
	widthCards: 2,
};
