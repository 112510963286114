import { createThemes } from '@babylon/ui-kit-styles/common/themes';
import { ThemeId } from '@babylon/ui-kit-styles/types/theme';

import veciCircuitsAdvantages from './styles/veci';

const circuitsAdvantagesThemes = createThemes({
	[ThemeId.veci]: veciCircuitsAdvantages,
});

export default circuitsAdvantagesThemes;
