import Image from '@babylon/ui-kit-base/components/multimedia/image';
import { Container } from '@babylon/ui-kit-styles/common/mixins/container.styled';
import type { FC } from 'react';

import { IMAGE_PROPS } from '../../constants/imageProps';
import type { CircuitsAdvantagesProps } from '../../types';
import * as S from './styled';

const CircuitsAdvantagesDesktop: FC<CircuitsAdvantagesProps> = ({
	props: { title = '', titleList = '', list, imageSrc },
}) => (
	<S.Container data-testid='CircuitsAdvantages'>
		<S.ContentWrapper>
			<Container>
				<S.BlockTitle title={title} />
				{titleList && <S.TitleList>{titleList}</S.TitleList>}
				<S.Content>
					{list?.length && (
						<S.List>
							{list.map(item => (
								<S.Item key={JSON.stringify(item)}>
									<S.StyledIcon className='nico-check' />
									<S.StyledInnerMarkdownHTML tag='span' content={item} />
								</S.Item>
							))}
						</S.List>
					)}
				</S.Content>
			</Container>
		</S.ContentWrapper>

		{imageSrc && (
			<S.ImageWrapper>
				<Image resize={IMAGE_PROPS} src={imageSrc} />
			</S.ImageWrapper>
		)}
	</S.Container>
);

export default CircuitsAdvantagesDesktop;
