import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { css } from 'styled-components';

import type { CircuitsAdvantagesStyles } from '../types';

const veciCircuitsAdvantagesStyles: CircuitsAdvantagesStyles = {
	Container: () => css`
		background-color: ${({ theme }) => theme.colors.green?.lightestL};
	`,
	StyledIcon: () => css`
		${FontMixin({ size: 'large' })};
		color: ${({ theme }) => theme.colors.primary.light};
	`,
	ImageWrapperEffect: () => css`
		--first-color: transparent;
		--second-color: ${({ theme }) => theme.colors.green?.lightestL};
		background-image: linear-gradient(to left, var(--first-color), var(--second-color));
	`,
};

export default veciCircuitsAdvantagesStyles;
