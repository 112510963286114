import { TitleWrapper } from '@babylon/ui-kit-carousels/components/carousel-generic/views/desktop/styled';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius, Margin, Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { css } from 'styled-components';

import type { CarouselCategoryStyles } from '../types';

const liverpoolCarouselCategoryStyles: CarouselCategoryStyles = {
	StyledCardSimple: () => css`
		height: 320px;
		a {
			${FlexMixin({ justify: 'flex-end', align: 'flex-start' })};
			p {
				background-color: ${({ theme }) => theme.colors.white.base};
				color: ${({ theme }) => theme.colors.grays.darker};
				text-transform: capitalize;
				${FontMixin({ weight: 'semiBold' })};
			}
		}
		h3 {
			${FontMixin({ size: 'large', height: 'large', weight: 'semiBold' })};
			${BorderRadius({ all: '8px' })};
			${Padding({ y: '12px', x: '20px' })};
			${Margin({ bottom: '4px', left: '4px' })};
			background-color: ${({ theme }) => theme.colors.white.base};
			color: ${({ theme }) => theme.colors.grays.darker};
			border: unset;
			text-transform: capitalize;
		}

		${({ theme }) => theme.media.phone.only} {
			height: 252px;
			${BorderRadius({ all: '8px' })};

			img {
				${BorderRadius({ all: '8px' })};
			}
			a {
				${BorderRadius({ all: '8px' })};
				background-color: transparent;
			}
			h3 {
				${FontMixin({ size: 'base', height: 'base', weight: 'semiBold' })};
				${Padding({ y: '8px', x: '16px' })};
				${Margin({ all: '0px' })};
			}
		}
	`,
	Carousel: () => css`
		${TitleWrapper} {
			${Margin({ bottom: '24px' })}
		}
		${({ theme }) => theme.media.phone.only} {
			h2 {
				${FontMixin({ weight: 'semiBold' })};
				${Margin({ bottom: '24px' })}
				color: ${({ theme }) => theme.colors.grays.darker};
			}
		}
	`,
};

export default liverpoolCarouselCategoryStyles;
